import React, { useContext } from 'react';

import ModalContainer from 'Components/Molecules/FormModalContainer';
import ModalTip from 'Components/Molecules/ModalTip';

import { SendEmailForm, SendEmailFormProps } from 'Forms/SendEmail';

import { Images } from 'Themes';

import { LocalizationContext } from 'i18n';

const EmailTemplateModal = (props: SendEmailFormProps) => {
  const { t } = useContext(LocalizationContext);

  return (
    <ModalContainer
      title={t('app.gallery.send.title')}
      icon={Images.email}
      subtitle={
        <ModalTip
          title={t('app.common.didYouKnow')}
          content={t('app.gallery.send.content', {
            link: '/app/settings/email-config',
          })}
        />
      }
    >
      <SendEmailForm {...props} />
    </ModalContainer>
  );
};

export default EmailTemplateModal;
