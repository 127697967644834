import {
  GalleryAccessPolicy,
  GalleryStatus,
  GalleryWorkmode,
  Locale,
  OrderType
} from 'Operations/__generated__/graphql';

export interface GalleryFormValues {
  id?: number;
  projectId?: number | null;
  galleryPresetId?: number | null;
  galleryPresetName?: string | null;
  name: string;
  url?: string | null;
  video?: string | null;
  contactId?: number | null;
  secondaryContactIds?: number[] | null;
  status: GalleryStatus;
  availableAt?: string | null;
  expiredAt?: string | null;
  shootedAt?: string | null;
  accessCode?: string;
  inPortfolio: boolean;
  catalogId?: number | null;
  catalogName?: string | null;
  workmode?: GalleryWorkmode | null;
  accessPolicy: GalleryAccessPolicy;
  locale?: Locale;
  isEmailRequired?: boolean;
}

export interface GalleryFormProps {}

export interface DataQueryParams {
  perPage: number;
  order: OrderType;
  page: number;
  search?: string;
}

export const PER_PAGE = 20;
export const EDGES_PARAMS = {
  perPage: PER_PAGE,
  order: OrderType.ASC,
  page: 1,
};
