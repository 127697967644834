import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import { Button, Divider, Flex, StepProps, Steps } from 'antd';
import { createStyles } from 'antd-style';
import { FormProps } from 'antd/lib';

import { Formik, FormikHelpers, FormikValues } from 'formik';
import { Form } from 'formik-antd';
import { compact } from 'lodash';

import { LocalizationContext } from 'i18n';

import * as Yup from 'yup';
import { AnySchema } from 'yup/lib/schema';

export type WizardFormType = 'create' | 'update';

export type Shape<Fields extends FormikValues> = {
  [Key in keyof Fields]: AnySchema<Fields[Key]>;
};

export type WizardPayload<V extends FormikValues> = {
  values: V;
  formikBag: FormikHelpers<V>;
  setStep: (step: string) => void;
};

export type WizardSubmitHandler<V extends FormikValues> = (payload: WizardPayload<V>) => Promise<void>;

export interface WizardStepProps<V extends FormikValues> extends React.PropsWithChildren {
  name: string;
  title?: string;
  validationSchema?: Yup.ObjectSchema<Shape<Partial<V>>>;
  onSubmit?: WizardSubmitHandler<V>;
  shouldSkip?: (values: V, stepNumber: number) => boolean;
}

interface WizardProps<V extends FormikValues> extends FormProps {
  formType?: WizardFormType;
  defaultStep?: string;
  initialValues: V;
  children: (ReactElement<WizardStepProps<V>> | null | undefined | false)[];
  debug?: boolean;
  onSubmit: (payload: WizardPayload<V>) => void;
  onSnapshotChange?: (values: V) => void;
}

const useStyles = createStyles(({ css, token, stylish }) => ({
  container: css`
    height: 100%;
  `,
  formContainer: css`
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 1px;
  `,
  steps: css`
    padding: 24px 24px 0 24px;
    transition: box-shadow 0.3s;
  `,
  scrolledSteps: css`
    ${stylish.shadow};
  `,
  form: css`
    flex: 1;
    overflow: auto;
    padding: 24px;
  `,
  actions: css`
    bacground-color: ${token.colorBgLayout};
    padding: 16px 24px;
    z-index: 100;
    ${stylish.shadow};
  `,
}));

const Wizard = <V extends FormikValues>({
  initialValues,
  children,
  debug,
  defaultStep,
  onSubmit,
  formType = 'create',
  onSnapshotChange,
  ...formProps
}: WizardProps<V>) => {
  const steps = useMemo(
    () => compact(React.Children.toArray(children) as ReactElement<WizardStepProps<V>>[]),
    [children],
  );

  const { t } = useContext(LocalizationContext);
  const { styles, cx } = useStyles();
  const formContainerRef = useRef<HTMLDivElement>(null);
  const [isScrolled, setIsScrolled] = useState(false);

  const [stepNumber, setStepNumber] = useState(defaultStep ? steps.findIndex(s => s.props.name === defaultStep) : 0);
  const [snapshot, setSnapshot] = useState(initialValues);

  const stepsTitle = useMemo<StepProps[]>(
    () => steps.map(({ props: { title } }, index) => ({ key: index, title })),
    [steps],
  );
  const step = useMemo(() => steps[stepNumber], [steps, stepNumber]);
  const totalSteps = useMemo(() => steps.length, [steps]);
  const isLastStep = useMemo(() => stepNumber === totalSteps - 1, [stepNumber, totalSteps]);

  const next = useCallback(
    (values: V) => {
      setSnapshot(values);
      let nextStep = stepNumber + 1;

      // Skip steps that should be skipped
      while (nextStep < steps.length && steps[nextStep].props.shouldSkip?.(values, stepNumber)) {
        nextStep += 1;
      }

      setStepNumber(Math.min(nextStep, steps.length - 1));
    },
    [stepNumber, steps],
  );

  const previous = useCallback(
    (values: V) => {
      setSnapshot(values);
      setStepNumber(Math.max(stepNumber - 1, 0));
    },
    [stepNumber],
  );

  const setStep = useCallback(
    (newStep: string) => {
      const step = steps.findIndex(s => s.props.name === newStep);
      if (step !== -1) {
        setStepNumber(step);
      }
    },
    [steps],
  );

  const handleSubmit = useCallback(
    async (values: V, formikBag: FormikHelpers<V>) => {
      if (step.props.onSubmit) {
        await step.props.onSubmit({ values, formikBag, setStep });
      }
      if (isLastStep || formType === 'update') {
        return onSubmit({ values, formikBag, setStep });
      } else {
        formikBag.setTouched({});
        next(values);
      }
    },
    [isLastStep, next, onSubmit, step],
  );

  useEffect(() => {
    // Listen for scroll events in formContainerRef
    const scrollListener = () => {
      setIsScrolled(formContainerRef.current?.scrollTop !== 0);
    };

    formContainerRef.current?.addEventListener('scroll', scrollListener);

    return () => {
      formContainerRef.current?.removeEventListener('scroll', scrollListener);
    };
  }, [formContainerRef]);

  return (
    <Flex vertical className={styles.container}>
      <Steps
        current={stepNumber}
        items={stepsTitle}
        labelPlacement="vertical"
        type="default"
        onChange={newStep => setStepNumber(newStep)}
        className={cx(styles.steps, isScrolled && styles.scrolledSteps)}
      />
      <Formik initialValues={snapshot} onSubmit={handleSubmit} validationSchema={step.props.validationSchema}>
        {formik => {
          if (debug) {
            console.log({ values: formik.values, errors: formik.errors });
          }

          const isErrors = Object.keys(formik.errors).length > 0;
          onSnapshotChange?.(formik.values);

          return (
            <Form {...formProps} className={cx(formProps.className, styles.formContainer)}>
              <div ref={formContainerRef} className={styles.form}>
                {step}
              </div>
              <Flex justify="space-between" className={styles.actions}>
                {stepNumber > 0 ? (
                  <Button onClick={() => previous(formik.values)}>{t('app.actions.previous')}</Button>
                ) : (
                  <div />
                )}
                <Flex align="center" gap="small">
                  {!isLastStep && (
                    <Button
                      type={formType === 'update' ? 'default' : 'primary'}
                      onClick={() => next(formik.values)}
                      disabled={isErrors}
                    >
                      {t('app.common.next')}
                    </Button>
                  )}
                  {formType === 'update' && !isLastStep && <Divider type="vertical" />}
                  {(isLastStep || formType === 'update') && (
                    <Button type="primary" htmlType="submit">
                      {t('app.common.validate')}
                    </Button>
                  )}
                </Flex>
              </Flex>
            </Form>
          );
        }}
      </Formik>
    </Flex>
  );
};

export const WizardStep = <V extends FormikValues>({ children }: WizardStepProps<V>) => <>{children}</>;

export default Wizard;
