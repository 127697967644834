import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';

import { useQuery } from '@apollo/client';
import { Button, Flex } from 'antd';

import { FormikBag, FormikProps, withFormik } from 'formik';
import { Form, Input, Switch } from 'formik-antd';
import { camelCase, compact, uniqBy } from 'lodash';

import Select from 'Components/Molecules/Form/Select';

import { LocalizationContext } from 'i18n';

import yup from 'Services/YupService';

import { GalleryAccessPolicy, GalleryWorkmode, GetCatalogsQuery, Locale } from 'Operations/__generated__/graphql';

import { GET_CATALOG } from 'Operations/Queries/Catalog/GetCatalog';
import { GET_CATALOGS } from 'Operations/Queries/Catalog/GetCatalogs';
import { GET_COMPANY_SETTINGS } from 'Operations/Queries/Company/GetCompanySettings';

export interface NewGalleryPresetFormValues {
  name: string;
  catalogId?: number;
  workmode?: GalleryWorkmode | null;
  locale: Locale;
  accessPolicy: GalleryAccessPolicy;
  isEmailRequired: boolean;
}

export interface EditGalleryPresetFormValues extends NewGalleryPresetFormValues {
  id: number;
}

const PER_PAGE = 20;
const CATALOG_PARAMS = {
  perPage: PER_PAGE,
};

const GalleryPresetForm = <T extends EditGalleryPresetFormValues | NewGalleryPresetFormValues>({
  isSubmitting,
  values,
  setFieldValue,
}: FormikProps<T>) => {
  const { t } = useContext(LocalizationContext);
  const catalogPage = useRef(1);

  const { catalogId } = values as EditGalleryPresetFormValues;

  const {
    data: catalogsData,
    loading: isCatalogsLoading,
    fetchMore: fetchMoreCatalogs,
  } = useQuery(GET_CATALOGS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      where: {
        ...CATALOG_PARAMS,
        page: catalogPage.current,
      },
    },
  });

  useQuery(GET_CATALOG, {
    skip: !catalogId,
    fetchPolicy: 'cache-and-network',
    variables: {
      where: {
        id: catalogId as number,
      },
    },
  });

  const { data: userSettingsData } = useQuery(GET_COMPANY_SETTINGS, {
    fetchPolicy: 'cache-first',
  });

  const catalogs: {
    _count: number;
    edges: GetCatalogsQuery['getCatalogs']['edges'];
  } = useMemo(() => {
    if (!catalogsData?.getCatalogs) {
      return { _count: 0, edges: [] };
    }

    return {
      edges: uniqBy([...catalogsData.getCatalogs.edges], 'id'),
      _count: catalogsData.getCatalogs._count,
    };
  }, [catalogsData?.getCatalogs]);

  useEffect(() => {
    if (!values.catalogId) {
      let defaultCatalog = catalogs.edges.find(catalog => catalog.isDefault);

      if (!defaultCatalog) {
        defaultCatalog = catalogs.edges[0];
      }

      if (defaultCatalog) {
        setFieldValue('catalogId', defaultCatalog.id);
      }
    }
  }, [catalogs.edges, setFieldValue, values.catalogId]);

  useEffect(() => {
    if (!values.workmode && userSettingsData?.getCompany?.settings) {
      setFieldValue('workmode', userSettingsData?.getCompany.settings.defaultGalleryWorkmode);
    }
  }, [setFieldValue, userSettingsData?.getCompany?.settings, values.workmode]);

  const renderAccessPolicyValues = useCallback(
    (translationsPath: string) => {
      const keys = Object.keys(GalleryAccessPolicy);
      const values = compact(
        keys.map(key => {
          if (key === GalleryAccessPolicy.OLD_PUBLIC || key === GalleryAccessPolicy.OLD_ACCESS_CODE) {
            return;
          }

          return (
            <Select.Option key={key} value={key} title={''}>
              {t(`${translationsPath}.${camelCase(key.toLowerCase())}`)}
            </Select.Option>
          );
        }),
      );
      return values;
    },
    [t],
  );

  const handleLoadMoreCatalogs = useCallback(() => {
    catalogPage.current += 1;
    fetchMoreCatalogs({
      variables: {
        where: {
          ...CATALOG_PARAMS,
          page: catalogPage.current,
        },
      },
    });
  }, [fetchMoreCatalogs]);

  return (
    <Form layout="vertical">
      <Flex>
        <Form.Item label={t('app.gallery.preset.label')} name="name" required hasFeedback={false}>
          <Input name="name" placeholder={t('app.gallery.preset.label')} size="large" />
        </Form.Item>
      </Flex>
      <Flex gap="middle">
        <Form.Item name="accessPolicy" label={t('app.gallery.accessType')} required hasFeedback={false}>
          <Select name="accessPolicy" size="large" getPopupContainer={trigger => trigger.parentNode}>
            {renderAccessPolicyValues('app.gallery.accessTypes')}
          </Select>
        </Form.Item>
        <Form.Item label={t('app.gallery.locale.label')} name="locale" required hasFeedback={false}>
          <Select name="locale" size="large" getPopupContainer={trigger => trigger.parentNode}>
            {Object.keys(Locale).map(lang => (
              <Select.Option key={lang} value={lang.toLowerCase()} title={''}>
                {t(`app.locale.${lang.toLowerCase()}`)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Flex>
      <Form.Item label={t('app.gallery.emailRequired.title')} name="isEmailRequired" hasFeedback={false}>
        <Switch name="isEmailRequired" /> {values.isEmailRequired ? t('app.common.yes') : t('app.common.no')}
      </Form.Item>
      <Flex gap="middle">
        <Form.Item label={t('app.gallery.workmode.label')} name="workmode" required hasFeedback={false}>
          <Select name="workmode" size="large" getPopupContainer={trigger => trigger.parentNode}>
            {Object.keys(GalleryWorkmode).map(mode => (
              <Select.Option key={mode} value={mode} title={''}>
                {t(`app.gallery.settings.defaultWorkmode.${camelCase(mode)}`)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label={t('app.common.catalog', { count: 1 })} name="catalogId" required hasFeedback={false}>
          <Select
            name="catalogId"
            size="large"
            loadMore={handleLoadMoreCatalogs}
            hasFetchAll={catalogs._count === catalogs.edges.length}
            loading={isCatalogsLoading}
            getPopupContainer={trigger => trigger.parentNode}
          >
            {catalogs.edges.map(catalog => (
              <Select.Option key={catalog.id} value={catalog.id} title={''}>
                {catalog.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Flex>

      <Flex justify="flex-end">
        <Button htmlType="submit" type="primary" size="large" loading={isSubmitting}>
          {t((values as EditGalleryPresetFormValues).id ? 'app.common.edit' : 'app.common.add')}
        </Button>
      </Flex>
    </Form>
  );
};

export interface NewGalleryPresetFormPayload {
  values: NewGalleryPresetFormValues;
  formikBag: FormikBag<NewGalleryPresetFormProps, NewGalleryPresetFormValues>;
}

export interface NewGalleryPresetFormProps {
  onSubmit: (payload: NewGalleryPresetFormPayload) => void;
  defaultValues: NewGalleryPresetFormValues;
}

export interface EditGalleryPresetFormPayload {
  values: EditGalleryPresetFormValues;
  formikBag: FormikBag<EditGalleryPresetFormProps, EditGalleryPresetFormValues>;
}

export interface EditGalleryPresetFormProps {
  onSubmit: (payload: EditGalleryPresetFormPayload) => void;
  defaultValues: EditGalleryPresetFormValues;
}

const gallerySchema = yup.object({
  name: yup.string().trim().required(),
  locale: yup.mixed<Locale>().required(),
  accessPolicy: yup.mixed<GalleryAccessPolicy>().required(),
  workmode: yup.mixed<GalleryWorkmode>().required(),
  catalogId: yup.number().required(),
  isEmailRequired: yup.boolean().required(),
});

const newGallerySchema: yup.SchemaOf<NewGalleryPresetFormValues> = gallerySchema.defined();
const editGallerySchema: yup.SchemaOf<EditGalleryPresetFormValues> = gallerySchema
  .shape({ id: yup.number().required() })
  .defined();

export const NewGalleryPresetForm = withFormik<NewGalleryPresetFormProps, NewGalleryPresetFormValues>({
  handleSubmit: (values, formikBag) => {
    formikBag.props.onSubmit({ values, formikBag });
  },
  mapPropsToValues: ({ defaultValues }) => defaultValues,
  validationSchema: newGallerySchema,
})(GalleryPresetForm);

export const EditGalleryPresetForm = withFormik<EditGalleryPresetFormProps, EditGalleryPresetFormValues>({
  handleSubmit: (values, formikBag) => {
    formikBag.props.onSubmit({ values, formikBag });
  },
  mapPropsToValues: ({ defaultValues }) => defaultValues,
  validationSchema: editGallerySchema,
})(GalleryPresetForm);
