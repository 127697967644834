import { ComponentType } from 'react';

import { Button } from 'antd';

import ReactModal from 'react-modal';

import Icon from 'Components/Atoms/Icon';
import Title from 'Components/Atoms/Title';

import { Colors, Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { useModals } from 'Hooks/Modal';

// Fix typings for react 18
ReactModal.setAppElement('#root');
const Modal = ReactModal as ComponentType<ReactModal['props']>;

const DescriptionContainer = styled.section`
  width: 296px;
  padding: 32px;
  background: ${Colors.primaryOptional};
  background: -moz-linear-gradient(0deg, ${Colors.primaryOptional} 0%, ${Colors.primaryMain} 100%);
  background: -webkit-linear-gradient(0deg, ${Colors.primaryOptional} 0%, ${Colors.primaryMain} 100%);
  background: linear-gradient(0deg, ${Colors.primaryOptional} 0%, ${Colors.primaryMain} 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="${Colors.primaryOptional}",endColorstr="${Colors.primaryMain}",GradientType=1);
  overflow-y: auto;
`;

const IllustrationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 96px;
  height: 96px;
  margin: 0 auto;
  margin-bottom: ${Metrics.smallMargin}px;
  border-radius: 48px;
  background: ${Colors.white};
`;

const Illustration = styled.img`
  display: block;
  width: 64px;
  height: auto;
`;

const FormContainer = styled.section<{ isPadding: boolean }>`
  overflow-y: scroll;
  position: relative;
  flex: 1;
  padding: ${({ isPadding }) => (isPadding ? `24px` : '0')};
`;

const CloseButton = styled(Button)`
  position: fixed;
  top: ${Metrics.smallMargin}px;
  right: 19px;
`;

const Styles = {
  overlay: {
    backgroundColor: undefined,
  },
  content: {
    top: undefined,
    left: undefined,
    right: undefined,
    bottom: undefined,
    border: undefined,
    background: undefined,
    borderRadius: undefined,
    padding: undefined,
    position: undefined,
  },
};

interface FormModalContainerProps {
  icon: string;
  title: string;
  subtitle?: JSX.Element | JSX.Element[];
  shouldCloseOnOverlayClick?: boolean;
  shouldCloseOnEsc?: boolean;
  isPadding?: boolean;
  children: JSX.Element | JSX.Element[];
}

const FormModalContainer = ({
  icon,
  title,
  subtitle,
  shouldCloseOnOverlayClick,
  shouldCloseOnEsc,
  children,
  isPadding = true,
}: FormModalContainerProps) => {
  const { closeModal } = useModals();

  return (
    <Modal
      isOpen
      style={Styles}
      onRequestClose={() => closeModal()}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      shouldCloseOnEsc={shouldCloseOnEsc}
    >
      <DescriptionContainer>
        <IllustrationContainer>
          <Illustration src={icon} />
        </IllustrationContainer>
        <Title level="h4" align="center" color={Colors.white}>
          {title}
        </Title>
        {subtitle}
        <div id="side-modal-content" />
      </DescriptionContainer>
      <FormContainer isPadding={isPadding}>
        {children}
        <CloseButton size="small" shape="circle" onClick={() => closeModal()}>
          <Icon name="close" size={20} />
        </CloseButton>
      </FormContainer>
    </Modal>
  );
};

export default FormModalContainer;
