import React, { useContext } from 'react';

import ModalContainer from 'Components/Molecules/FormModalContainer';

import GalleryForm, { GalleryFormProps } from 'Forms/Gallery';

import { Images } from 'Themes';

import { LocalizationContext } from 'i18n';

interface NewGalleryModalProps extends GalleryFormProps {
  name: 'new';
}
interface EditGalleryModalProps extends GalleryFormProps {
  name: 'edit';
}

const GalleryModal = (props: NewGalleryModalProps | EditGalleryModalProps) => {
  const { t } = useContext(LocalizationContext);

  return (
    <ModalContainer title={t(`app.gallery.modal.${props.name}`)} icon={Images.gallery} isPadding={false}>
      {props.name === 'edit' ? <GalleryForm {...props} /> : <GalleryForm {...props} />}
    </ModalContainer>
  );
};

export default GalleryModal;
