import React, { ReactNode, ReactPortal, useEffect, useState } from 'react';

import { createPortal, unmountComponentAtNode } from 'react-dom';

interface PortalHandler {
  render: ({ children }: { children: ReactNode }) => ReactPortal | null;
  remove: () => void;
}

const usePortal = (el: HTMLElement | null) => {
  const [portal, setPortal] = useState<PortalHandler>({
    render: () => null,
    remove: () => null,
  });

  useEffect(() => {
    if (el) portal.remove();

    const newPortal: PortalHandler = {
      // @ts-ignore - Missmatch between react-dom and react ReactNode type
      render: ({ children }) => (!!el ? createPortal(children, el) : null),
      remove: () => (el ? unmountComponentAtNode(el) : null),
    };

    setPortal(newPortal);
    return () => {
      newPortal.remove();
    };
  }, [el]);

  return portal.render;
};

export default usePortal;
