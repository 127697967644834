import React from 'react';

import { Flex } from 'antd';
import { createStyles } from 'antd-style';
import { Link } from 'react-router-dom';

import ReactMarkdown from 'react-markdown';

import Icon from 'Components/Atoms/Icon';

import { Colors } from 'Themes';

import { useModals } from 'Hooks/Modal';

interface Props extends React.PropsWithChildren {
  content?: string;
  title: string;
}

const useStyles = createStyles(({ css, token }) => ({
  title: css`
    display: flex;
    gap: ${token.sizeSM}px;
    justify-content: flex-start;
    align-items: center;
    font-weight: 800;
    margin-top: ${token.sizeXL}px;
    font-style: italic;
    font-size: ${token.fontSizeLG}px;
    color: ${token.colorWhite};
  `,
  text: css`
    color: ${token.colorWhite};
    font-size: ${token.fontSize}px;
    margin-bottom: ${token.sizeSM}px;
  `,
  link: css`
    color: ${token.colorWhite};
    text-decoration: underline;
  `,
}));

const ModalTip = ({ content, title, children: tipChildren }: Props) => {
  const { closeModal } = useModals();
  const { styles } = useStyles();

  return (
    <Flex vertical gap="middle">
      <h5 className={styles.title}>
        <Icon color={Colors.white} name="tips" size={22} />
        <span>{title}</span>
      </h5>
      <div>
        {content && (
          <ReactMarkdown
            children={content}
            components={{
              p: ({ children }) => <p className={styles.text}>{children}</p>,
              a: ({ children, ...props }) => (
                <Link
                  to={props.href || '.'}
                  className={styles.link}
                  onClick={() => {
                    closeModal();
                  }}
                >
                  {children}
                </Link>
              ),
            }}
          />
        )}
        {tipChildren}
      </div>
    </Flex>
  );
};

export default ModalTip;
