import React, { useContext, useEffect } from 'react';

import { Divider, Flex } from 'antd';

import { useFormikContext } from 'formik';
import { camelCase } from 'lodash';

import SelectCard from 'Components/Molecules/Cards/SelectCard';

import { Images } from 'Themes';

import { LocalizationContext } from 'i18n';

import generateId from 'Helpers/GenerateId';

import { GalleryAccessPolicy } from 'Operations/__generated__/graphql';

import { GalleryFormValues } from './types';

const GalleryAccessPolicyStep = () => {
  const { t } = useContext(LocalizationContext);
  const { setFieldValue, values, initialValues } = useFormikContext<GalleryFormValues>();

  // Generate an access code if the access policy is changed from public to access code
  useEffect(() => {
    if (
      initialValues.accessPolicy === GalleryAccessPolicy.PUBLIC &&
      values.accessPolicy === GalleryAccessPolicy.ACCESS_CODE &&
      !initialValues.accessCode
    ) {
      setFieldValue('accessCode', generateId({ size: 6 }));
    } else {
      setFieldValue('accessCode', '');
    }
  }, [initialValues.accessPolicy, initialValues.accessCode, values.accessPolicy, setFieldValue]);

  return (
    <Flex vertical>
      <Flex vertical gap="large">
        <SelectCard
          icon={Images.publicPolicy}
          title={t(`app.gallery.accessTypes.publicNEW.title`)}
          isSelected={values.accessPolicy === GalleryAccessPolicy.PUBLIC}
          onClick={() => setFieldValue('accessPolicy', GalleryAccessPolicy.PUBLIC)}
        >
          <p>{t(`app.gallery.accessTypes.publicNEW.content`)} </p>
        </SelectCard>
        <SelectCard
          icon={Images.accessCodePolicy}
          title={t(`app.gallery.accessTypes.accessCodeNEW.title`)}
          isSelected={values.accessPolicy === GalleryAccessPolicy.ACCESS_CODE}
          onClick={() => setFieldValue('accessPolicy', GalleryAccessPolicy.ACCESS_CODE)}
        >
          {t(`app.gallery.accessTypes.accessCodeNEW.content`)}
        </SelectCard>
      </Flex>
      <Divider />
      <SelectCard
        icon={Images.email}
        title={t(`app.gallery.emailRequired.title`)}
        isSelected={!!values.isEmailRequired}
        onClick={() => setFieldValue('isEmailRequired', !values.isEmailRequired)}
        bordered={false}
      >
        <p>{t(`app.gallery.emailRequired.content`)}</p>
      </SelectCard>
    </Flex>
  );
};

export default GalleryAccessPolicyStep;
