import { useCallback, useContext } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { App, Button, Dropdown, Menu, Modal, Spin, Tabs } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import { camelCase } from 'lodash';

import Container from 'Components/Atoms/Container';
import ContentContainer from 'Components/Atoms/ContentContainer';
import Icon from 'Components/Atoms/Icon';
import Layout from 'Components/Atoms/Layout';

import RoundButton from 'Components/Molecules/Buttons/RoundButton';
import Header from 'Components/Molecules/Header';

import GalleryCoverTab from 'Pages/App/Galleries/Tabs/GalleryCoverTab';
import GallerySpacingTab from 'Pages/App/Galleries/Tabs/GallerySpacingTab';
import GalleryWatermarksTab from 'Pages/App/Galleries/Tabs/GalleryWatermarksTab';
import GalleriesShowSkeleton from 'Pages/App/Skeletons/GalleriesShowSkeleton';

import { EditGalleryPresetFormPayload } from 'Forms/GalleryPreset';

import { Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import { useModals } from 'Hooks/Modal';

import getErrorCode from 'Helpers/GetErrorCode';

import { GET_GALLERY_PRESET } from 'Operations/Queries/GalleryPreset/GetGalleryPreset';

import { DELETE_GALLERY_PRESET } from 'Operations/Mutations/GalleryPreset/DeleteGalleryPreset';
import { UPDATE_GALLERY_PRESET } from 'Operations/Mutations/GalleryPreset/UpdateGalleryPreset';

import GalleryProductsTab from '../Galleries/Tabs/GalleryProductsTab';
import GalleryThemeTab from '../Galleries/Tabs/GalleryThemeTab';

const { TabPane: TabPaneAnt } = Tabs;

const StyledTabsContainer = styled(ContentContainer)`
  flex: 1;
  width: 100%;
  padding-top: 0;
  margin-top: ${Metrics.baseMargin}px;
`;

const StyledTabs = styled(Tabs)`
  flex: 1;
`;

const TabPane = styled(TabPaneAnt)`
  padding-top: ${Metrics.smallMargin}px;
  padding-bottom: ${Metrics.smallMargin}px;
`;

const GalleryPresetShow = () => {
  const { t } = useContext(LocalizationContext);
  const { message } = App.useApp();
  const { openModal, closeModal } = useModals();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const presetId = id ? parseInt(id, 10) : undefined;

  const { data, loading: isGalleryPresetLoading } = useQuery(GET_GALLERY_PRESET, {
    skip: !presetId,
    variables: {
      where: { id: presetId as number },
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
  });

  const [updateGalleryPreset, { loading: isUpdateLoading }] = useMutation(UPDATE_GALLERY_PRESET);

  const [deleteGalleryPreset] = useMutation(DELETE_GALLERY_PRESET);

  const handleEditOnSubmit = useCallback(
    async ({ values: { id, ...galleryPresetData }, formikBag }: EditGalleryPresetFormPayload) => {
      try {
        await updateGalleryPreset({ variables: { where: { id }, data: galleryPresetData } });

        message.success(t('app.message.gallery.update.success'));
        formikBag.setSubmitting(false);

        closeModal();
      } catch (error) {
        const errorMessage = t(`app.message.error.somethingWentWrong`);

        message.error(errorMessage);

        formikBag.setSubmitting(false);
      }
    },
    [closeModal, t, updateGalleryPreset],
  );
  const galleryPreset = data?.getGalleryPreset;

  const handleDeleteGalleryPreset = async () => {
    try {
      if (presetId) {
        await deleteGalleryPreset({
          variables: {
            where: {
              id: presetId,
            },
          },
        });

        message.success(t('app.message.galleryPreset.delete.success'));
        navigate('/app/galleries/presets');
      }
    } catch (error) {
      const errorCode = getErrorCode(error);
      message.error(t(`app.message.gallery.delete.error.${camelCase(errorCode)}`));
    }
  };

  if (isGalleryPresetLoading || !galleryPreset) {
    return <GalleriesShowSkeleton />;
  }

  const { name, galleryAppearance, watermark, watermarkMode } = galleryPreset;

  const headerButtons = [
    <Button
      key="edit"
      size="large"
      onClick={() => {
        openModal('GALLERY_PRESET', {
          name: 'edit',
          onSubmit: handleEditOnSubmit,
          defaultValues: {
            id: galleryPreset?.id,
            name,
            locale: galleryPreset?.locale,
            accessPolicy: galleryPreset?.accessPolicy,
            catalogId: galleryPreset?.catalog?.id,
            workmode: galleryPreset?.workmode,
            isEmailRequired: galleryPreset?.isEmailRequired,
          },
        });
      }}
    >
      <Icon name="edit" /> {t('app.common.edit')}
    </Button>,
    <Dropdown
      key="more"
      menu={{
        items: [
          {
            key: 'delete',
            danger: true,
            label: (
              <>
                {isUpdateLoading && <Spin size="small" />} {t('app.common.delete')}
              </>
            ),
            onClick: () => {
              return Modal.confirm({
                title: t('app.confirm.delete'),
                okText: t('app.common.delete'),
                cancelText: t('app.common.cancel'),
                onOk() {
                  handleDeleteGalleryPreset();
                },
              });
            },
          },
        ],
      }}
      placement="bottomRight"
    >
      <RoundButton size="large" icon="more" />
    </Dropdown>,
  ];

  return (
    <>
      <Header
        title={<Container align="center">{name}</Container>}
        breadcrumbContent={[
          { text: t('app.menu.home'), url: '/app/dashboard' },
          { text: t('app.common.galleryPresets'), url: '/app/galleries/presets' },
          { text: name || '-' },
        ]}
        buttons={headerButtons}
      />
      <Layout>
        <Container flex={1} direction="column">
          <StyledTabsContainer shadow rounded padding="baseMargin">
            <StyledTabs defaultActiveKey="1" destroyInactiveTabPane>
              <TabPane tab={t('app.gallery.tabs.theme', { count: 1 })} key="theme">
                {name && galleryAppearance && <GalleryThemeTab gallery={galleryPreset} />}
              </TabPane>
              <TabPane tab={t('app.gallery.tabs.cover')} key="cover">
                {name && galleryAppearance && <GalleryCoverTab gallery={galleryPreset} />}
              </TabPane>
              <TabPane tab={t('app.gallery.tabs.appearance')} key="appearance">
                {galleryAppearance && (
                  <GallerySpacingTab galleryAppearance={galleryAppearance} watermarkMode={watermarkMode} />
                )}
                {!galleryAppearance && <p>{t('app.gallery.appearance.notFound')}</p>}
              </TabPane>
              <TabPane tab={t('app.gallery.tabs.watermark')} key="watermark">
                <GalleryWatermarksTab
                  presetId={presetId}
                  currentWatermarkId={watermark?.id || null}
                  galleryAppearance={galleryAppearance}
                  currentWatermarkMode={watermarkMode}
                />
              </TabPane>
              <Tabs.TabPane tab={t('app.common.products', { count: 2 })} key="products">
                <GalleryProductsTab galleryPresetId={presetId} />
              </Tabs.TabPane>
            </StyledTabs>
          </StyledTabsContainer>
        </Container>
      </Layout>
    </>
  );
};

export default GalleryPresetShow;
