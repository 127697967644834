import React, { useContext, useEffect } from 'react';

import { useQuery } from '@apollo/client';
import { Flex } from 'antd';

import { useFormikContext } from 'formik';
import { camelCase } from 'lodash';

import SelectCard from 'Components/Molecules/Cards/SelectCard';

import { Images } from 'Themes';

import { LocalizationContext } from 'i18n';

import { GalleryWorkmode } from 'Operations/__generated__/graphql';

import { GET_COMPANY_SETTINGS } from 'Operations/Queries/Company/GetCompanySettings';

import { GalleryFormValues } from './types';

const GalleryWorkmodeStep = () => {
  const { t } = useContext(LocalizationContext);
  const { setFieldValue, values } = useFormikContext<GalleryFormValues>();

  const { data: userSettingsData } = useQuery(GET_COMPANY_SETTINGS, {
    fetchPolicy: 'cache-first',
  });

  // Set the default workmode from the company settings
  useEffect(() => {
    if (!values.workmode && userSettingsData?.getCompany?.settings) {
      setFieldValue('workmode', userSettingsData?.getCompany.settings.defaultGalleryWorkmode);
    }
  }, [setFieldValue, userSettingsData?.getCompany?.settings, values.workmode]);

  return (
    <Flex vertical gap="large">
      <SelectCard
        icon={Images.readyToSaleMode}
        title={t(`app.gallery.settings.defaultWorkmode.${camelCase(GalleryWorkmode.READY_FOR_SALE)}`)}
        isSelected={values.workmode === GalleryWorkmode.READY_FOR_SALE}
        onClick={() => setFieldValue('workmode', GalleryWorkmode.READY_FOR_SALE)}
      >
        <p>{t('app.gallery.workmode.readyForSale.description')}</p>
      </SelectCard>
      <SelectCard
        icon={Images.retouchMode}
        title={t(`app.gallery.settings.defaultWorkmode.${camelCase(GalleryWorkmode.RETOUCH_FIRST)}`)}
        isSelected={values.workmode === GalleryWorkmode.RETOUCH_FIRST}
        onClick={() => setFieldValue('workmode', GalleryWorkmode.RETOUCH_FIRST)}
      >
        <p>{t('app.gallery.workmode.retouchFirst.description')}</p>
      </SelectCard>
    </Flex>
  );
};

export default GalleryWorkmodeStep;
