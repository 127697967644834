import React from 'react';

import { Flex } from 'antd';
import { createStyles } from 'antd-style';

interface Props {
  icon: string;
  title: string;
  value?: string;
}
const useStyles = createStyles(({ css, token, stylish }) => ({
  imageContainer: css`
    background-color: ${token.colorWhite};
    border-radius: ${token.borderRadiusRounded}px;
    height: 44px;
    width: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  image: css`
    height: auto;
    width: 24px;
  `,
  title: css`
    font-family: ${token.fontFamily};
    font-size: ${token.fontSize}px;
    font-weight: 400;
    text-transform: uppercase;
    color: ${token.colorWhite};
    margin: 0;
  `,
  content: css`
    font-size: ${token.fontSizeSM}px;
    color: ${token.colorWhite};
    margin: 0;
  `,
}));
const ModalRecapItem = ({ icon, title, value }: Props) => {
  const { styles } = useStyles();
  return (
    <Flex gap="small">
      <div className={styles.imageContainer}>
        <img src={icon} alt={title} className={styles.image} />
      </div>
      <Flex gap="small" vertical>
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.content}>{value}</p>
      </Flex>
    </Flex>
  );
};

export default ModalRecapItem;
