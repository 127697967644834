import { gql } from 'Operations/__generated__';

export const GET_GALLERY = gql(/* GraphQL */ `
  query GetGallery($where: GalleryGetWhereType!) {
    getGallery(where: $where) {
      ...GalleryCoreFields
      ... on GalleryAdmin {
        watermark {
          id
        }
        watermarkMode
        isClientCodesAdminEnabled
        photosOrder
        photosCustomOrder
        accessPolicy
        accessCode
        slug
        isEmailRequired
        video
        secondaryContacts {
          ...ContactCoreFields
        }
        currency {
          symbol
        }
        _count {
          photosViewCount
        }
        jobs {
          id
          jobType
          percent
          status
          createdAt
        }
        includedProducts {
          ...IncludedProductCoreFields
        }
        mandatoryProduct {
          id
          name
        }
      }
    }
  }
`);
