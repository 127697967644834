import { createStyles } from 'antd-style';

export const useStepsStyle = createStyles(({ css, prefixCls, token, stylish }) => ({
  className: css`
    .${prefixCls}-steps {
      &-item {
        &-content {
          margin-top: ${token.sizeXS}px !important;
          width: 92px !important;
        }
        .${prefixCls}-steps-item-tail {
          margin-inline-start: 46px !important;
        }
        .${prefixCls}-steps-item-icon {
          margin-inline-start: 23px !important;
        }
        &-title {
          font-size: ${token.fontSizeXS}px;
          color: ${token.colorPrimary} !important;
          line-height: normal;
          margin-top: 0;
        }
        &-description {
          font-size: ${token.fontSizeXS}px;
        }

        .${prefixCls}-steps-item-icon {
          .${prefixCls}-steps-icon {
            font-weight: ${token.fontWeightStrong};
          }
        }

        &-wait {
          .${prefixCls}-steps-item-title {
            color: ${token.colorTextDisabled} !important;
          }
          .${prefixCls}-steps-item-icon {
            background-color: transparent;
            border-color: ${token.colorTextDisabled};
            > .${prefixCls}-steps-icon {
              color: ${token.colorTextDisabled};
            }
          }
        }
        &-active {
          .${prefixCls}-steps-item-icon {
            background-color: transparent;
            > .${prefixCls}-steps-icon {
              color: ${token.colorPrimary};
            }
          }
        }

        &-finish {
          .${prefixCls}-steps-item-icon {
            background-color: ${token.colorPrimary};
            border-color: ${token.colorPrimary};
            > .${prefixCls}-steps-icon {
              color: ${token.colorText};
            }
          }
        }
      }
    }
  `,
}));
