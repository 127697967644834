import { useCallback, useContext, useMemo } from 'react';

import { MutationHookOptions, useMutation } from '@apollo/client';
import { App } from 'antd';
import { useNavigate } from 'react-router-dom';

import { camelCase } from 'lodash';

import { WizardPayload } from 'Components/Molecules/Form/Wizard';

import { GalleryFormValues } from 'Forms/Gallery/types';

import { LocalizationContext } from 'i18n';

import getErrorCode from 'Helpers/GetErrorCode';

import {
  CreateGalleryMutation,
  Exact,
  GalleryAccessPolicy,
  GalleryCreateInputType,
  GalleryStatus,
  GalleryWorkmode,
  Locale
} from 'Operations/__generated__/graphql';

import { CREATE_GALLERY } from 'Operations/Mutations/Gallery/CreateGallery';

import { useModals } from './Modal';
import { useCurrentUser } from './useCurrentUser';

export const useGalleryModal = (
  options?: MutationHookOptions<CreateGalleryMutation, Exact<{ data: GalleryCreateInputType }>>,
) => {
  const { openModal, closeModal } = useModals();
  const { currentUser } = useCurrentUser();
  const { message } = App.useApp();
  const navigate = useNavigate();

  const { t } = useContext(LocalizationContext);

  const currentUserLocale = useMemo<Locale>(() => (currentUser?.me.locale as Locale) || Locale.EN, [currentUser]);

  const [createGalleryMutation] = useMutation(CREATE_GALLERY, options);

  const handleOnSubmit = useCallback(
    async ({
      values: { catalogName, galleryPresetName, ...galleryData },
      formikBag,
    }: WizardPayload<GalleryFormValues>) => {
      try {
        const gallery = await createGalleryMutation({ variables: { data: galleryData } });

        message.success(t('app.message.gallery.create.success'));
        formikBag.setSubmitting(false);
        closeModal();

        if (gallery.data?.createGallery) {
          navigate(`/app/galleries/${gallery.data.createGallery.id}`);
        }
      } catch (error) {
        console.log(error);

        const errorKey = camelCase(getErrorCode(error));

        if (errorKey === 'uniqConstraintFailed') {
          formikBag.setFieldError('url', t(`app.message.error.${errorKey}`, { field: 'url' }));
          message.error(t(`app.message.error.${errorKey}`, { field: 'url' }));
        } else {
          message.error(t('app.message.error.somethingWentWrong'));
        }

        formikBag.setSubmitting(false);
      }
    },
    [createGalleryMutation, message, t, navigate, closeModal],
  );

  const createGallery = useCallback(
    (initialData?: Partial<GalleryFormValues>) => {
      openModal('GALLERY', {
        name: 'new',
        onSubmit: handleOnSubmit,
        defaultValues: {
          name: '',
          contactId: undefined,
          secondaryContactIds: [],
          status: GalleryStatus.OFFLINE,
          locale: currentUserLocale,
          availableAt: undefined,
          expiredAt: undefined,
          shootedAt: undefined,
          accessPolicy: GalleryAccessPolicy.PUBLIC,
          accessCode: undefined,
          inPortfolio: false,
          workmode: GalleryWorkmode.READY_FOR_SALE,
          catalogId: undefined,
          galleryPresetId: null,
          ...initialData,
        },
      });
    },
    [currentUser, currentUserLocale, openModal, handleOnSubmit],
  );

  return { createGallery };
};
